.navbar {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  border-bottom: 1px solid grey;
}
.audit-search {
  width: 260px;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  padding-left:23px ;
}
.navbar-left-section {
  display: flex;
  align-items: center;
  gap: 13px;
  width: 45%;
}
.status-Filter-values{
  overflow: hidden;
}
.navbar-left-section .navbar-left-checkbox{
  margin-left: 14px;
}
.recall-btn{
  background-color: #4999C9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
}
.navbar-left-section input[type="text"],
.navbar-left-section select {
  border: none;
  outline: none;
}
.workflow-refresh-btn {
  border: 1px solid #a4d0ed;
  padding: 6px;
  border-radius: 10px;
  background-color: #f4fdff;
  color: #4094c8;
  width: 150px;
  display: flex;
  justify-content: space-around;
}
.workflow-refresh-btn {
  cursor: pointer;
  font-size: 14px;
}
.workflow-refresh-btn span {
  width: 14px;
  height: 14px;
}
.workflow-refresh-btn span img {
  width: 100%;
}
.navbar-right-section {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 15px;
  width: 55%;
  padding-left: 15px;
}

.filter-wrapper {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  display: flex;
  gap: 6px;
  font-family: sans-serif;
  color: #fff;
  background-color: #4999C9;
}
.status-value {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 2px;
  font-size: 14px;

}
.priority-value {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 2px;
  font-size: 14px;

}
p.ellpsis{
  max-width: 110px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
.insurer-value {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 2px;
  font-size: 14px;

}
.close-filter-btn:hover{
cursor: pointer;
}
.filter-wrapper:hover {
  cursor: pointer;
}
.modal-footer .btn-primary {
  background-color: #032f49;
  border-color: #032f49;
}
.modal-footer .btn-primary:hover {
  background-color: #032f49;
  border-color: #032f49;
}

.search-bar {
  position: relative;
}
.search-icon {
  position: absolute;
  left: 5px;
  top: calc(50% - 7px);
  width: 15px;
  height: 15px;
} 
.search-recommendations {
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow: hidden scroll;
  top: 27px;
  z-index: 2;
  /* padding: 12px 12px 12px 0px; */
  background-color: #fff;
}
.search-recommendations li {
  list-style-type: none;
  padding-left: 5px;
  padding-bottom: 5px;
}
.search-recommendation {
  background-color: #a4d0ed;
  border: 1px solid #a4d0ed;
}
.search-recommendation:hover {
  background-color: #8dcbf5;
  cursor: pointer;
}
.hcp-file-dropdown-webworkflow .btn-primary{
  background-color: transparent;
  color: #000;
  border: none;
}
.webworkflow-dropdown-menu{
  max-height: 400px;  
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 8px;
  padding-right: 8px;
}
.webworkflow-dropdown-menu .form-check{
  width: 400px;
  font-size: 14px;
}
.date-picker-wrapper{
  width: 100% !important;
  flex-wrap: wrap;
}
.load-filters::-webkit-scrollbar {
  width: 6px;
  height: 5px; 
}

.load-filters::-webkit-scrollbar-track {
  background: #f1f1f1;  
}

.load-filters::-webkit-scrollbar-thumb {
  background: #888;  
  border-radius: 6px;  
}

.load-filters::-webkit-scrollbar-thumb:hover {
   cursor: pointer; 
}

/* filter design */
.filters-border{
  border-right: 1px solid rgb(215, 214, 214);
  padding: 0 16px 16px;
}
.file-type-wrapper {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;  
}
.checkbox-container input {
  margin-right: 8px;  
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 18px 24px; /* Space between items */
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input {
  margin-right: 8px;
}

.filter-modal-body > div {
  border: 1px solid #E6EDF4;
}

.filters-border  h6 {
  white-space: nowrap;
  font-size: 14px;
  background-color: #fff;
  position: sticky;
  top: 0;
  padding-top: 16px;
}

.checkbox-container label {
  white-space: nowrap;
}

.dates-wrapper {
  padding: 10px;
}

.dates-wrapper > h6 {
  white-space: nowrap;
}

.dates-wrapper .date-picker-wrapper {
  display: flex;
  gap: 50px;
  position: relative;
}

.dates-wrapper .date-picker-wrapper > div {
  max-width: 96px;
  width: 96px;
  height: 38px;
  position: relative;
}

.dates-wrapper .date-picker-wrapper > div.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

.dates-wrapper .date-picker-wrapper > div:nth-child(1)::after {
  content: "";
  border-bottom: 1px solid #667085;
  position: absolute;
  width: 10px;
  top: 19px;
  right: -30px;
}

.dates-wrapper .date-picker-wrapper > div input {
  width: 100%;
  height: 38px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  text-align: center;
}

.datepicker-tooltip {
  position: relative;
  cursor: pointer; 
}
.datepicker-tooltip:hover::after {
  content: attr(data-title); 
  position: absolute; 
  bottom: 100%;
  left: 50%; 
  transform: translateX(-50%); 
  background-color: #575757; 
  color: #fff; 
  padding: 5px 10px; 
  border-radius: 4px; 
  font-size: 12px; 
  white-space: nowrap;
  opacity: 0.5; 
  pointer-events: none;
  visibility: hidden; 
}
.datepicker-tooltip:hover::after {
  opacity: 1; 
  visibility: visible;
}


.date-header {
  margin-top: 24px;
}
.filter-footer button{
  width: auto;
}
.toggle-conversion-btn {
  border: 1px solid;
  border-radius: 6px;
  background: #504e4e;
  color: #fff;
  margin-left: 8px;
}
.load-filters{
  max-height: 100px;
    overflow: auto;
    /* max-width: 320px; */
}

.search-recommendations::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.search-recommendations::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7A7C82; 
  border-radius: 10px;
}
 
.search-recommendations::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

.search-recommendations::-webkit-scrollbar-thumb:hover {
  background: gray; 
  cursor: pointer;
}